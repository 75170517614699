import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';

import { User } from './classes/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apollo: Apollo,
    private router: Router
  ) {}

  private userInfoSource: BehaviorSubject<User | null> =  new BehaviorSubject(null);
  userInfo: Observable<User> = this.userInfoSource.asObservable();

  authenticateUser(idToken: string): void {
    this.apollo
    .watchQuery<Response>({
      query: gql`
      {
        authenticateUser(idToken: "${idToken}") {
          bearerToken
          expiresAt
          userId
          email
          firstName
          lastName
          profilePicture
          status
          previews
          language
        }
      }
      `
    })
    .valueChanges.subscribe(res => {
      if(res && res.data && res.data['authenticateUser'] && res.data['authenticateUser'].email) {
        let authenticatedUser = {...res.data['authenticateUser']};
        authenticatedUser.previews = authenticatedUser.previews.join(',');
        this.setSession(authenticatedUser);
        const preLoginUrl = localStorage.getItem('preLoginUrl');
        localStorage.removeItem('preLoginUrl');
        if(preLoginUrl) {
          const path = new URL(preLoginUrl, 'http://domain').pathname;
          const queryParams = new URL(preLoginUrl, 'http://domain').searchParams;
          let queryParamsObject = {};
          for (const [key, value] of queryParams.entries()) {
            queryParamsObject[key] = value;
          }
          this.router.navigate([path], { queryParams: queryParamsObject });
        } else if((this.router.url.split('/')[1] && this.router.url.split('/')[1].startsWith('auth')) || (this.router.url.split('/')[2] && this.router.url.split('/')[2].startsWith('auth'))) {
          this.router.navigate(['/']);
        }
      } else {
        console.error(`Authentication error`);
      }
    });
  }

  private setSession(authenticatedUser) {
    localStorage.setItem('bearerToken', authenticatedUser.bearerToken);
    localStorage.setItem("expiresAt", authenticatedUser.expiresAt);
    localStorage.setItem("userId", authenticatedUser.userId);
    localStorage.setItem("email", authenticatedUser.email);
    localStorage.setItem("firstName", authenticatedUser.firstName);
    localStorage.setItem("lastName", authenticatedUser.lastName);
    localStorage.setItem("profilePicture", authenticatedUser.profilePicture);
    localStorage.setItem("status", authenticatedUser.status);
    localStorage.setItem("previews", authenticatedUser.previews);
    localStorage.setItem("language", authenticatedUser.language);
    this.userInfoSource.next(authenticatedUser);
  }

  updateUserSettings(params: { language?: string }): void {
    const authenticatedUser = {...this.userInfoSource.value};
    if(params.language) {
      authenticatedUser.language = params.language;
      localStorage.setItem("language", authenticatedUser.language);
    }
    this.userInfoSource.next(authenticatedUser);
  }
  
  loadUserInfoFromLocalStorage(): void {
    if(Math.floor(Date.now() / 1000) < parseInt(localStorage.getItem("expiresAt"))) {
      this.userInfoSource.next({
        bearerToken: localStorage.getItem("bearerToken"),
        expiresAt: parseInt(localStorage.getItem("expiresAt")),
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        profilePicture: localStorage.getItem("profilePicture"),
        status: localStorage.getItem("status"),
        previews: localStorage.getItem("previews"),
        language: localStorage.getItem("language")
      });
    } else {
      this.logout();
    }
  }
  
  getStoredUserStatus(): String | null {
    return localStorage.getItem("status");
  }
  
  logout() {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("profilePicture");
    localStorage.removeItem("status");
    localStorage.removeItem("previews");
    localStorage.removeItem("language");
    this.userInfoSource.next(null);
  }

}
